<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="单位名称">
          <el-input v-model="searchForm.unitName" autocomplete="off" size="small" placeholder="请输入单位名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="行政区域">
          <el-cascader :disabled="isLoad" v-model="searchForm.areaCodes" size="small" placeholder="请选择行政区域" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:180px"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" icon="el-icon-search" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="riskList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="industryName" label="所属行业" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="evaluateLevel" label="评估等级" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-show="scope.row.evaluateLevel==='A'" type="success" size="size">{{scope.row.evaluateLevel}}</el-tag>
            <el-tag v-show="scope.row.evaluateLevel==='B'" type="warning" size="size">{{scope.row.evaluateLevel}}</el-tag>
            <el-tag v-show="scope.row.evaluateLevel==='C'" type="danger" size="size">{{scope.row.evaluateLevel}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="ranks" label="排名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="totalScore" label="评估分数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="evaluateDate" label="评估月份" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.evaluateDate|dateFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看" placement="top">
              <el-button size="mini" @click="open('score',scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      riskList: [],
      currentPage: 1,
      total: 0,
      loading: false,
      isLoad: false,
      areaList: [],
      searchForm: {
        areaCodes: [],
        unitName: ''
      }
    }
  },
  created () {
  },
  mounted () {
    this.initTable()
    this.getAreaList()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/unitScore/list', { params: data })
      if (result.code === 200) {
        this.loading = false
        this.riskList = result.data.result
        this.total = result.data.total
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    async open (flag, row) {
      switch (flag) {
        case 'score':
          this.$router.push(`/home/score/${row.unitId}`)
          break
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    changeKey (array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.map {
  width: 100%;
  height: 500px !important;
  .bmView {
    height: 100% !important;
    > div:first-child {
      height: 500px !important;
    }
  }
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
.drawer-form {
  font-size: 0;
  padding: 10px 40px;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
      float: left;
    }
    ::v-deep .el-form-item__content {
      float: left;
    }
  }
}
::v-deep  :focus {
  outline: 0;
}
.risk-estimate {
  ::v-deep .el-dialog__body {
    padding: 0;
    height: calc(100vh - 54px);
  }
}
</style>
